import React, { Component } from 'react';

class GoogleAd extends Component {
  googleInit = null;

  componentDidMount() {
    // console.log("wsx componentDidMount()");
    if (this.props.WithAds && this.props.GoogleAdSlot) {
      // I think this script is needed here
      const script = document.createElement("script");
      script.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      script.async = true;
      document.body.appendChild(script);
  
      this.googleInit = setTimeout(() => {
        if (typeof window !== 'undefined')
          (window.adsbygoogle = window.adsbygoogle || []).push({});
      }, 1000);
    }
  }

  componentWillUnmount() {
    if (this.googleInit) clearTimeout(this.googleInit);
  }

  render() {
    const { UnitName, WithAds, GoogleClientId, GoogleAdSlot,
            ClassNames, Style, Format } 
    = this.props;
  
    if (WithAds && GoogleAdSlot) {
      // console.log("wsx doing an ad", WithAds, UnitName, GoogleAdSlot);
      const divName = `advert-${UnitName}`;
      const insClass = "adsbygooge" + UnitName;
      return (
        <div className={ClassNames || divName}>
          <ins
            className={insClass}
            style={Style || { display: 'block', textAlign: "center" }}
            data-ad-client={GoogleClientId}
            data-ad-slot={GoogleAdSlot}
            data-ad-format={Format || "auto"}
            data-full-width-responsive="true"
          ></ins>
        </div>
      );
    } else {
      // console.log("wsx no ad here", WithAds, UnitName, GoogleAdSlot);
      return (<div> {UnitName} area</div>)
    }
  }
}

export default GoogleAd;
//     <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
