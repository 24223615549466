import React from "react";
import "./NavWidget.css";

function ContentLink(page, onSelectPage) { // Dateline
  return (
    <div key={page.PageId} className="nav-item">
      <button className="nav-page-link" onClick={() => onSelectPage(page.PageId)} >
        <div className="nav-page-title">
          { page.Title }
        </div>
        <div className="nav-dateline">
          { page.Dateline }
        </div>
      </button>
    </div>
  )
}

function ContentLinks(bookContent, onSelectPage) {
  const links = [];

  if (bookContent) {
    for (const pageId in bookContent) {
      links.push(ContentLink(bookContent[pageId], onSelectPage));
    }
  }
  return links;
}

export default class NavWidget extends React.Component {
  render() {
    const bookContent = this.props.BookContent;
    const onSelectPage = this.props.OnSelectPage;
    return (
      <div className="nav-area">
        <div className="nav-title">
          Choose
        </div>
        { ContentLinks(bookContent, onSelectPage) }
      </div>
    )
  }
}
