import React from "react";
import "./WebsiteTitle.css";

export default class WebsiteTitle extends React.Component {
  announce(siteConfig, CurrentVersion) {
    console.log(`Version ${CurrentVersion}. SiteConfig: `, JSON.stringify(siteConfig, null, 2));
  }

  render() {
    const { BookName, Tagline, SiteConfig, CurrentVersion } = this.props;
      return (
      <div className="title-area" onClick={() => this.announce(SiteConfig, CurrentVersion)}>
        <div className="site-title">
          { BookName }
        </div>
        <div className="site-tagline">
          { Tagline }
        </div>
      </div>
    );
  }
}
