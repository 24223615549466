import React from "react";
import "./ContentWidget.css";

function createMarkup(text) {
  return {__html: text};
}

export default class ContentWidget extends React.Component {
  render() {
    const currentPage = this.props.CurrentPage;

    if (currentPage) {
      const title = currentPage.Title;
      const picture = currentPage.Picture;
      const altText = currentPage.AltText;
      const dateline = currentPage.Dateline;
      const htmlText = currentPage.HtmlText;
    
      return (
        <div className="content-area">
          <div className="storytitle-area">{title}</div>
          <div className="dateline-area">{dateline}</div>
          <div className="storypicture-area">
            <img src={picture} className="story-picture-image" alt={altText}/>
          </div>
          <div className="storytext-area">
            <p dangerouslySetInnerHTML={createMarkup(htmlText)} className="story-text"></p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="content-area">
        </div>
      )
    }
  }
}
