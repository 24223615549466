import React from 'react';
import NET from 'networkobjects';
import './BlogApp.css';
import WebsiteTitle from "./WebsiteTitle/WebsiteTitle.js";
import NavWidget from './NavWidget/NavWidget';
import ContentWidget from "./ContentWidget/ContentWidget.js";
import GoogleAd from "./AdServices/googleAd.js";
const AXI = require('axios').default;

const VERSION = "1.01";

// Better Solution Required. No trailing slash.
const GoogleClientId = "ca-pub-2136261085524041";

const PostHeaders = {
  'Content-Type': 'text/plain',
  'Accept': '*/*',
};

export default class BlogApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CurrentVersion: VERSION,
      Book: null,
      PageId: "welcome",
      SiteConfig: window.siteConfig,
    }
  }

  // On page load...
  componentDidMount() {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const pageId = params.get("pageId") || this.state.PageId;
    this.OnSelectPage(pageId);
    document.title = this.state.SiteConfig.title;

    if (this.state.SiteConfig.withAds && this.state.SiteConfig.googleAdSlots.length < 2) {
      console.log("Error: Too few windows.googleAdSlots");
      this.setState({
        ...this.state,
        SiteConfig: {
          ...this.state.SiteConfig,
          withAds: false
        }
      })
    }
  }

  // Navigate to a specific book and page
  async OnSelectPage(pageId) {
    if (!this.state.Book) {
      const contentServiceUrl = this.state.SiteConfig.contentServiceUrl + "/content";

      // POST a GetBookRequest to /content
      const reply = await AXI.post(
        contentServiceUrl, 
        new NET.GetBookRequest(this.state.SiteConfig.bookId),
        { headers: PostHeaders }
      );

      const data = reply.data;
      if (data.ReasonCode === NET.ReasonCodes.Success) {
        const newState = Object.assign({}, this.state);
        newState.PageId = pageId;
        newState.Book = data.Payload;
        this.setState(newState);
      } else {
        alert(`Unable to retrieve book '${this.state.SiteConfig.bookId}'`);
        console.log("Unexpected response: ", data);
      }
    }
    this.setState({
      ...this.state,
      PageId: pageId
    })
  }

  render() {
    let currentPage = null;

    if (this.state.Book) {
      currentPage = this.state.Book.Content[this.state.PageId];
    }

    return (
      <div className="page-area">
        <WebsiteTitle 
          BookName = { this.state.Book?.BookName } 
          Tagline = { this.state.Book?.Tagline }
          SiteConfig = { this.state.SiteConfig }
          CurrentVersion = { this.state.CurrentVersion }
        />
        <div className="body-area">
          <NavWidget 
            BookId = { this.state.SiteConfig.bookId }
            BookContent = { this.state.Book?.Content }
            OnSelectPage = { pageId => this.OnSelectPage(pageId) }
          />
          <ContentWidget 
            CurrentPage = { currentPage }
          />
          <GoogleAd 
            UnitName="Unit-1"
            WithAds = { this.state.SiteConfig.withAds }
            GoogleClientId = { GoogleClientId }
            GoogleAdSlot = { this.state.SiteConfig.googleAdSlots[0] || null }
          />
        </div>
        <GoogleAd 
            UnitName="Unit-2"
            WithAds = { this.state.SiteConfig.withAds }
            GoogleClientId = { GoogleClientId }
            GoogleAdSlot = { this.state.SiteConfig.googleAdSlots[1] || null }
        />
      </div>
    );
  }
}
