//-------------------------------------------------------------------------------------------------
//  Network Objects - Network Requests and Replies
//-------------------------------------------------------------------------------------------------

//-------------------------------------------------------------------------------------------------
// Requests
const RequestCodes = {
  GetBookRequest: "GetBookRequest",
}

function GetBookRequest(bookId) {
  return {
    RequestCode: RequestCodes.GetBookRequest,
    BookId: bookId,
  }
}

//-------------------------------------------------------------------------------------------------
//  Replies

const ReasonCodes = {
  Success: "Success",
  BadJSONFormat: "BadJSONFormat",
  NotFound: "NotFound",
  ProcessingException: "ProcessingException",
}

function StandardReply(successFlag, reasonCode, message, payload) {
  return {
    SuccessFlag: successFlag,
    ReasonCode: reasonCode,
    Message: message,
    Payload: payload,
  }
}

module.exports = {
  RequestCodes,
  GetBookRequest,
  ReasonCodes,
  StandardReply,
}
